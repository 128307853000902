/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import Head from 'next/head';
import { HeadTags } from '@/shared/types/home.types';
import { Fragment, createElement } from 'react';

interface HeadProps {
  headPage: HeadTags[];
}

export default function HeadersPage({ headPage }: HeadProps) {
  return (
    <Head>
      <meta name="author" content="Canaltech" />
      {headPage &&
        headPage.map((page, index) => {
          return (
            <Fragment key={index}>
              {page.tag === 'title'
                ? createElement(page.tag, { key: index, ...page.attr }, page.content)
                : createElement(page.tag, { key: index, ...page.attr })}
              {page.tag === 'meta' &&
                page.attr.hasOwnProperty('http-equiv') &&
                createElement(page.tag, { key: index, httpEquiv: 'Cache-control', content: page.attr.content })}
            </Fragment>
          );
        })}
    </Head>
  );
}
