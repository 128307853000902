import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import Top from '@components/Layout/Top';
import { PageWrapper } from '@components/Layout/PageWrapperMenu/styles';
import GlobalContainer from '@/components/Layout/GlobalContainer';
import { LayoutTypes } from '@/shared/types/home.types';
import HeadersPage from '@/components/Layout/Headers';
import useBackground from '@/hooks/useBackground';
import usePageViewComscoreRequest from '@/hooks/usePageViewComscoreRequest';
import usePageViewComscoreScript from '@/hooks/usePageViewComscoreScript';
import Banner from './Banner';
import { ApplySchema } from '../Internals/InternalSchema';
import { CazambaAds } from '../Ads/CazambaAds';
import AdsGeneratorMemo from '../Ads/Google/AdsTypeGeneratorsMemo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useScriptsToRoute from '@/hooks/useScriptsToRoute';
import { Navbar } from '../Navbar';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface LayoutProps {
  children: ReactNode | ReactNode[];
  layoutData?: LayoutTypes;
  showBackgroundAd?: boolean;
  headingTag?: 'h1' | 'span';
  isAdvertorial?: boolean;
  dataLayer?: {
    category?: string;
    author?: string;
    editor?: string;
    contentType?: string;
  };
}

export default function Layout({
  children,
  layoutData,
  showBackgroundAd = true,
  headingTag = 'span',
  isAdvertorial = false,
  dataLayer = undefined,
}: LayoutProps) {
  const AdsEvents = dynamic(() => import('@/components/Ads/Google/AdsEvents'), { ssr: false });
  const BackgroundGoogleAds = dynamic(() => import('@/components/Ads/Google/BackgroundGoogleAds'), { ssr: false });
  const OneAOneAdGeneratorMemo = dynamic(() => import('@/components/Ads/Google/OneAOne'), { ssr: false });
  const InterstitialsAds = dynamic(() => import('@/components/Ads/Google/Interstitial'), { ssr: false });
  const { isAdActive } = useBackground();

  const router = useRouter();
  const pathname = router.pathname;
  const cleanPath = pathname.split('?')[0].replace(/\/$/, '');

  const { useGpt, useGtm, useNavbar, useFullScreen, useComscore, specifyId } = useScriptsToRoute(cleanPath);
  const { segmentacoes, tagsData, navBarData, breadcrumbList } = layoutData || {};
  let schema = layoutData?.schema;

  const workingList = (breadcrumbList || []).filter((item: { url: string }) => item.url && item.url !== '/');

  if (!schema) {
    schema = {
      breadcrumb: null,
      website: null,
      organization: null,
    };
  }

  if (!schema.breadcrumb) {
    schema.breadcrumb = {
      '@context': 'https://schema.org',
      '@id': 'https://www.canaltech.com.br/#breadcrumb',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 0,
          name: 'Home',
          item: `${publicRuntimeConfig.NEXT_PUBLIC_URL}`,
        },
        ...workingList.map((item, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: item.titulo,
          item: `${publicRuntimeConfig.NEXT_PUBLIC_URL}${item.url}`,
        })),
      ],
    };
  }

  if (useComscore) usePageViewComscoreRequest();
  if (useComscore) usePageViewComscoreScript();

  return (
    <GlobalContainer useNavbar={useNavbar}>
      <Head>
        {dataLayer !== undefined && useGtm && (
          <script
            id="data-layer"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];

              dataLayer.push({
                'category': '${dataLayer.category ? dataLayer.category : ''}',
                'authorData': '${dataLayer.author ? dataLayer.author : ''}',
                'editorData': '${dataLayer.editor ? dataLayer.editor : ''}',
                'contentType': '${dataLayer.contentType ? dataLayer.contentType : ''}',
              });
            `,
            }}
          />
        )}
        {useGtm && (
          <script
            id="gtm-script"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${typeof specifyId === 'string' ? specifyId : publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
              `,
            }}
          />
        )}
      </Head>
      <ApplySchema schema={schema} />
      {useGpt && <OneAOneAdGeneratorMemo />}
      {!isAdvertorial && <CazambaAds />}
      {useGpt && segmentacoes && <AdsEvents segmentacoes={segmentacoes} />}
      {tagsData && <HeadersPage headPage={tagsData} />}
      {useNavbar && navBarData && <Navbar navBarContent={navBarData} headingTag={headingTag} />}
      <Banner />
      {useGpt && <Top Ads={showBackgroundAd ? isAdActive : false} shouldHaveAds={!isAdvertorial} />}
      {!isAdvertorial && useGpt && <BackgroundGoogleAds />}
      <PageWrapper ads={showBackgroundAd && useGpt ? isAdActive : false} fullScreen={useFullScreen}>
        {children}
        {useGpt && <InterstitialsAds />}
      </PageWrapper>
      {!isAdvertorial && useGpt && <AdsGeneratorMemo type="anchor" />}
      {!isAdvertorial && useGpt && <AdsGeneratorMemo type="Anchor_Footer" />}
    </GlobalContainer>
  );
}
